import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="smooth-scroll"
export default class extends Controller {
  scrollToAnchor(event) {
    event.preventDefault();

    const targetId = event.target.getAttribute("href").substring(1);
    if (!targetId) return;

    const targetElement = document.getElementById(targetId);
    if (!targetElement) return;

    const offcanvasElement = document.getElementById("navbar");
    const offcanvas = offcanvasElement
      ? window.bootstrap.Offcanvas.getInstance(offcanvasElement)
      : null;

    const scrollToTarget = () => {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth"
      });
    };


    if (offcanvas) {
      offcanvas.hide();
      offcanvasElement.addEventListener('hidden.bs.offcanvas', scrollToTarget, {once: true});
    } else {
      scrollToTarget();
    }
  }
}

